import * as React from 'react';
import loadable from '@loadable/component';
import withMicroFrontendProps, { SEOHead } from 'AppShell/Microfrontend';
import { SignupAndLoginProps } from 'AppShell/appshell_types';
import useSignupAndLoginBellaProps from 'Client/hooks/MicroFrontentd/SignupAndLogin/useSignupAndLoginBellaProps';

const SignupAndLogin = loadable(() => import('mf-signup-and-login/Main'), {
    ssr: false,
    resolveComponent: components => {
        if (components.default.ShopToClubOverlay) {
            return components.default.ShopToClubOverlay;
        }
        // TODO: remove fallback after mf was deployed to production, after a week.
        // if a old version of microfrontend is deployed we should fallback to the default export.
        // can be removed after the mf version was updated
        return components.default;
    },
}) as SignupAndLoginProps['ShopToClubOverlay'];

const SignupAndLoginWithBellaProps = withMicroFrontendProps(SignupAndLogin, 'mf-signup-and-login');

const SignupAndLoginMicroFrontend = () => {
    const bellaProps = useSignupAndLoginBellaProps();
    return (
        <div>
            <SEOHead noIndex />
            <SignupAndLoginWithBellaProps hostProps={bellaProps} />
        </div>
    );
};

export default SignupAndLoginMicroFrontend;
