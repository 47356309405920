import { PageType, useCurrentPageType } from 'Client/hooks/UrlManager/pageTypeHooks';
import { ShopToClubConfigState } from 'AppShell/appshell_types';
import { useBellaSelector } from 'Client/redux/hooks';

export interface SignupAndLoginBellaProps {
    pageType: PageType;
    isShopToClubEnabled: boolean;
    badgeBackgroundColor: string;
}

const useSignupAndLoginBellaProps = (): SignupAndLoginBellaProps => {
    const shopToClubConfig = useBellaSelector<ShopToClubConfigState>(state => state.configurations.shopToClub);
    const { pageType } = useCurrentPageType();
    const isShopToClubEnabled = shopToClubConfig?.isEnabled || false;
    // We are mapping old border color config to background color.
    const badgeBackgroundColor = shopToClubConfig?.topBorderColor || '';
    return { pageType, isShopToClubEnabled, badgeBackgroundColor };
};

export default useSignupAndLoginBellaProps;
